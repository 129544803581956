*{
  margin: 0;
  padding: 0;

}
body {
  margin: 0;
 
  overflow-x: hidden; /* Desactiva el scroll horizontal */

  background-color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
